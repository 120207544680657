export const getStyles = () => ({
	serviceFlag: {
		wrapper:
			"lg:tw-absolute tw-mt-4 lg:tw-mt-0 lg:tw-top-2 tw-left-[-5px] tw-flex",
		flag: `tw-bg-[#1d8ce0] tw-rounded-[3px] tw-w-[28px] tw-h-[28px] tw-flex tw-items-center tw-justify-center tw-shadow-[1px_3px_3px_0px_rgba(211,211,211,1.0)]`,
		number: "tw-text-white tw-text-sm tw-font-bold tw-leading-4 tw-mt-0",
		text: "tw-ml-1 tw-rounded-[3px] tw-text-white tw-text-sm tw-bg-[#1d8ce0] tw-font-semibold tw-leading-4 tw-flex tw-items-center tw-pl-2.5 tw-pr-4 tw-shadow-[1px_3px_3px_0px_rgba(211,211,211,1.0)]",
	},
	featuredCompanyHeader: {
		wrapper: "tw-p-2 tw-bg-[#44a4ed40] tw-text-center",
		contentWrapper:
			"tw-text-[#010f0f] tw-leading-6 tw-font-semibold tw-text-xs tw-tracking-[-0.00875rem] xs:tw-text-sm lg:tw-text-base lg:tw-tracking-[-0.01rem]",
	},
	companyLogo: { picture: "", image: "tw-w-full" },
	rating: {
		wrapper:
			"tw-mt-4 tw-flex tw-items-center tw-gap-1.5 tw-rounded-full tw-bg-white tw-px-4 tw-py-1 tw-shadow-[0px_10px_64px_0px_rgba(50,50,93,0.1),0px_5px_32px_0px_rgba(0,0,0,0.08)]",
		score:
			"tw-text-[24px] tw-font-extrabold tw-leading-[24px] tw-text-[#213132]",
		stars: "tw-flex tw-flex-col tw-gap-1",
		starColor: "tw-text-[#24b679] tw-text-lg",
		count:
			"tw-ml-0.5 tw-mt-0 tw-text-xs tw-font-semibold tw-leading-[100%] tw-text-[#213132]",
	},
	benefitsList: {
		wrapper: "",
		list: "tw-flex tw-flex-col tw-gap-1 tw-justify-center",
		listItem:
			"tw-text-[#213132] tw-text-[14px] md:tw-text-[15px] tw-font-normal tw-leading-2 tw-flex tw-gap-1 tw-bg-none tw-pl-0",
	},
	visitLink: {
		wrapper:
			"tw-flex tw-h-[48px] tw-w-[175px] lg:tw-w-full tw-group tw-justify-center tw-py-2 lg:tw-py-3 tw-px-2 tw-bg-[#24b679] tw-rounded-[3px] tw-mb-4 lg:tw-mb-3 hover:tw-bg-[#169d65] tw-transition-all tw-duration-300",
		contentWrapper:
			"tw-text-white tw-text-base lg:tw-text-[20px] tw-font-bold lg:tw-tracking-[-0.18px] tw-mr-0 tw-pt-[7px] lg:tw-pt-0 lg:tw-mr-1.5",
		iconClass:
			"tw-hidden tw-text-2xl tw-text-white tw-transition-all tw-duration-300 group-hover:tw-translate-x-1 lg:tw-inline-flex",
	},
	companyBadge: {
		wrapper:
			"tw-w-max tw-flex tw-bg-[#24b679] tw-justify-center tw-items-center tw-p-1 tw-my-1 tw-rounded tw-shadow-lightShadow",
		text: "tw-text-white tw-font-bold tw-text-xs tw-my-0 tw-mx-1",
	},
});
