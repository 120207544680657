import { useContext, useRef } from "react";
import * as constants from "@/config/constants";
import { getIsNotUserFiltered } from "@/selectors";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";

import { CheckIcon } from "../../../client/assets/icons/common/CheckIcon";
import { RightArrowIcon } from "../../../client/assets/icons/common/RightArrowIcon";
import { BaseBenefitsList } from "../../BaseComponents/BaseBenefitsList";
import BaseCompanyBadge from "../../BaseComponents/BaseCompanyBadge";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import BaseCompanyName from "../../BaseComponents/BaseCompanyName";
import { BaseFeaturedCompanyHeader } from "../../BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../BaseComponents/BaseLinkWrapper";
import BaseServiceFlag from "../../BaseComponents/BaseServiceFlag";
import { BaseVisitLink } from "../../BaseComponents/BaseVisitLink";
import { CompanyContext } from "../../CompanyList/CompanyContext";
import { PhoneLink } from "./PhoneLink";
import { getStyles } from "./styles";

export const DemoGridTCR = () => {
	const { isFeaturedCompany } = useContext(CompanyContext);
	const { isNotUserFiltered } = useSelector(getIsNotUserFiltered);
	const styles = getStyles(isFeaturedCompany);
	const nodeRef = useRef(null);

	return (
		<Transition
			nodeRef={nodeRef}
			in
			appear={!isNotUserFiltered}
			timeout={constants.FILTERING_TRANSITION_DURATION}
			unmountOnExit
		>
			<div
				className={`tw-m-auto tw-mb-4 tw-max-w-[550px] tw-bg-white tw-shadow-[0px_3px_3px_0px_rgba(211,211,211,0.5)] first:tw-mt-4 hover:tw-shadow-[0px_3px_3px_0px_rgba(211,211,211,1.0)] lg:tw-mb-6 lg:tw-max-w-none ${
					isFeaturedCompany &&
					"tw-mb-8 tw-mt-6 tw-rounded-[3px] tw-border tw-border-solid tw-border-[#1d8ce0] lg:tw-mb-16 lg:tw-border-2"
				}`}
			>
				<BaseLinkWrapper>
					<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
				</BaseLinkWrapper>
				<div className="tw-relative">
					<BaseLinkWrapper>
						<BaseServiceFlag classNames={styles.serviceFlag} />
					</BaseLinkWrapper>

					<div className="tw-flex tw-flex-col tw-flex-wrap tw-gap-4 tw-p-4 lg:tw-flex-row lg:tw-gap-0 lg:tw-p-4">
						<div className="tw-flex tw-w-full tw-justify-center lg:tw-max-w-[18%] lg:tw-shrink-0 lg:tw-grow-0 lg:tw-basis-[21.28%] lg:tw-pl-9 lg:tw-pr-[30px]">
							<div className="tw-flex tw-w-40 tw-flex-col tw-justify-center tw-gap-2 lg:tw-gap-4">
								<BaseLinkWrapper>
									<BaseCompanyLogo classNames={styles.companyLogo} />
									<BaseGridRatingHorizontal classNames={styles.rating} />
								</BaseLinkWrapper>
							</div>
						</div>

						<div className="tw-flex tw-w-full tw-max-w-full lg:tw-max-w-[64%] lg:tw-shrink-0 lg:tw-grow-0 lg:tw-basis-[59.7%] lg:tw-pl-[30px] lg:tw-pr-[22px]">
							<BaseLinkWrapper>
								<div className="tw-flex tw-w-full tw-flex-col">
									<BaseCompanyName className="tw-mb-0 tw-text-xl tw-font-bold tw-tracking-[-0.2px] tw-text-[#000F10] lg:tw-text-2xl lg:tw-leading-8 lg:tw-tracking-[-0.48px]" />
									<BaseCompanyBadge
										classNames={styles.companyBadge}
										iconColor={"white"}
									/>
									<BaseBenefitsList
										{...styles.benefitsList}
										icon={
											<CheckIcon className="tw-mr-1 tw-mt-1 tw-text-[#1d8ce0]" />
										}
									/>
								</div>
							</BaseLinkWrapper>
						</div>

						<div className="tw-flex tw-w-full tw-max-w-full lg:tw-max-w-[19%] lg:tw-shrink-0 lg:tw-grow-0 lg:tw-basis-[19.09%] lg:tw-pl-0 lg2:tw-pl-[22px]">
							<div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-around sm:tw-flex-row lg:tw-flex-col lg:tw-justify-center">
								<PhoneLink className={"tw-w-[175px] lg:tw-w-full"} />
								<BaseLinkWrapper className={"tw-w-fit lg:tw-w-full"}>
									<BaseVisitLink
										{...styles.visitLink}
										icon={<RightArrowIcon />}
									>
										Visit Site
									</BaseVisitLink>
								</BaseLinkWrapper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	);
};
